$(window).on('load', function() {


    setTimeout(function() {
        $('.load').fadeOut();
        $('.form').fadeIn();
    }, 2500);

    Form.blockHideFields();
    Form.radioInput();
    Form.formEffect();
    Form.checkErrors();
    FormBuilderAuto.load();
    FormBuilderResidencia.load();
    FormBuilderVida.load();
    FormBuilderViagem.load();
    FormBuilderConsorcioAuto.load();
    FormBuilderConsorcioImovel.load();
    // $('main.seguro-automovel').find('select').chosen({no_results_text:'Nenhum resultado encontrado', width: '100%'});
});