$(function() {

    // Máscaras
    var brasillianPhoneMask = function (val) {
        return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
    }
    var maskOptions = {
      onKeyPress: function(val, e, field, options) {
          field.mask(brasillianPhoneMask.apply({}, arguments), options);
        }
    };

    $('.moeda input').mask('00.000.000,00', {reverse: true});
    $('.telefone input').mask(brasillianPhoneMask, maskOptions);
    $('.fabricacao_modelo input').mask('0000/0000');
    $('.placa input').mask('SSS-0000');
    $('.cep input').mask('00000-000');
    $('.cpf input').mask('000.000.000-00');
    $('.data input').mask('00/00/0000');
    $('.cnpj input').mask('00.000.000/0000-00');
    $('.chassi input').mask('AAAAAAAAAAAAAAAAA');

    $('.moeda input').attr('type', 'tel');
    $('.telefone input').attr('type', 'tel');
    $('.fabricacao_modelo input').attr('type', 'tel');
    $('.cep input').attr('type', 'tel');
    $('.cpf input').attr('type', 'tel');
    $('.data input').attr('type', 'tel');
    $('.cnpj input').attr('type', 'tel');
});