var AjaxRequest = {
    getApoliceImage: function(post_title, callback) {
        jQuery.post(
            home_url + '/wp-admin/admin-ajax.php',
            {
                'action': 'get_apolice_image',
                'post_title': post_title
            },
            function(data){
                if(data != "0" && data != "null"){
                    data = JSON.parse(data);

                    if(data.url != undefined){
                        var element = {
                            title:  'Apólice modelo: <strong>' +post_title+'</strong>',
                            body:   '<div class="apolice-image">'+
                                        '<div class="box-title">' +
                                            '<h3>Você escolheu <strong>'+post_title+'</strong></h3>' +
                                            '<p>Clique na imagem para ampliar</p>' +
                                        '</div>' +
                                        '<img src="'+data.url+'" alt="'+data.alt+'" title="'+data.title+'">'+
                                    '</div>'
                        };

                        callback(element);
                    }
                }
            }
        );
    },
    getMarcas: function(callback) {
        var url = 'https://fipe.parallelum.com.br/api/v1/carros/marcas';

        $.getJSON(url, function(data) {
            var options = ['<option value="">Selecione</option>'];

            $.each(data, function(key, val) {
                options.push('<option value="' + val.nome + '" data-code="' + val.codigo + '">' + val.nome + '</option>');
            });

            callback(options);
        });
    },
    getModelos: function(codigo_marca, callback) {
        var url = 'https://fipe.parallelum.com.br/api/v1/carros/marcas/' + codigo_marca + '/modelos';

        $.getJSON(url, function(data) {
            var options = ['<option value="">Selecione</option>'];

            $.each(data.modelos, function(key, val) {
                options.push('<option value="' + val.nome + '">' + val.nome + '</option>');
            });

            callback(options);
        });
    },
    getEndereco: function(cep, returnJSON, callback) {
        cep         = (cep) ? cep : '00000000';
        var url     = 'https://viacep.com.br/ws/' + cep + '/json/';
        var address = "";

        if(!cep) return false;

        $.getJSON(url, function(data) {

            if(data.erro !== true){
                if(returnJSON)
                    address = data;
                else
                    address = data.logradouro + ', ' + data.bairro + ' - ' + data.localidade + '/' + data.uf;
            }

            callback(address);
        });
    },
    getContinentes: function(callback) {
        var url = "http://www.geonames.org/childrenJSON?geonameId=6295630&lang=pt";
        var options = ['<option value="">Selecione</option>'];

        $.getJSON(url, function(data){
            $.each(data.geonames, function(key, val){
                options.push('<option value="' + val.name + '" data-code="' + val.geonameId + '">' + val.name + '</option>');
            });

            callback(options);
        });

    },
    getPaises: function(geoId, callback) {
        var url     = "http://www.geonames.org/childrenJSON?geonameId=" + geoId + '&lang=pt';
        var options = ['<option value="">Selecione</option>'];

        $.getJSON(url, function(data){
            $.each(data.geonames, function(key, val){
                options.push('<option value="' + val.name + '" data-code="' + val.geonameId + '">' + val.name + '</option>');
            });

            callback(options);
        });
    },
    getEstados: function(geoId = "3469034", callback) {
        var url     = "http://www.geonames.org/childrenJSON?geonameId=" + geoId + '&lang=pt';
        var options = ['<option value="">Selecione</option>'];

        $.getJSON(url, function(data){
            $.each(data.geonames, function(key, val){
                options.push('<option value="' + val.name + '" data-code="' + val.geonameId + '">' + val.name + '</option>');
            });

            callback(options);
        });
    },
    getCidades: function(geoId, callback){
        var url     = "http://www.geonames.org/childrenJSON?geonameId=" + geoId + '&lang=pt';
        var options = ['<option value="">Selecione</option>'];

        $.getJSON(url, function(data){
            $.each(data.geonames, function(key, val){
                options.push('<option value="' + val.name + '">' + val.name + '</option>');
            });

            callback(options);
        });
    }
};