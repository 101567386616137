$(function() {

    // Máscaras
    var brasillianPhoneMask = function (val) {
        return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
    }
    var maskOptions = {
      onKeyPress: function(val, e, field, options) {
          field.mask(brasillianPhoneMask.apply({}, arguments), options);
        }
    };

    $('.telefone input').mask(brasillianPhoneMask, maskOptions);
    $('.telefone input').attr('type', 'tel');
});