var Form = {
	radioInput: function () {
		var input = $(".ufb-form-field");

		$.each(input, function() {
			var radio = $(this).find('input').attr('type');

			if(radio == 'radio') {
				$(this).addClass('radio');
			}
		});
	},

	infoButton: function (element) {
        var seguradoras = $('.seguradoras select');
		seguradoras.after('<div class="image-content"></div>');
		var image = $('.seguradoras .image-content');
		image.append(element.body);

		$('.seguradoras .image-content img').unbind('click').click(function () {
            var apolice_modal = $('#apoliceModal');

            apolice_modal.modal('show');
		});

		seguradoras.find('.info-button').hide();
	},

	formEffect: function () {
		var elements = $(".form-seguro .ufb-form-field-wrap input, .ufb-form-field-wrap select, .ufb-form-field-wrap textarea");

		$(elements).on('focusin click',function () {
			var box   = $(this).closest(".form-seguro .ufb-form-field-wrap").next();
			var group = $(this).closest("[class*='group']");

			setTimeout(function () {
				if(box.hasClass('form-header')) {
					box.addClass('effect');
					box.next().addClass('effect');

					return false;
				}

				if(group.length > 0){

					var classe = $.grep(group.attr('class').split(" "), function(val, key){
				       return val.indexOf('group') === 0;
				    }).join();

					var length = classe.substr(classe.indexOf('-') + 1);

					while(length > 0) {


						if(box.css('display') == 'none'){
							do{
								box = box.next();
							}while(box.css('display') == 'none');

							box.addClass('effect');

							if(box.hasClass('form-header')) {
								box.next().addClass('effect');
							}

							continue;
						}

						box.addClass('effect');
						box = box.next();

						length--;
					}

					return false;
				}

				if(box.css('display') == 'none'){
					do{
						box = box.next();
					}while(box.css('display') == 'none');

					box.addClass('effect');

					if(box.hasClass('form-header')) {
						box.next().addClass('effect');
					}

					return false;
				}

				if(box.next().hasClass('form-agree')) {
					box.addClass('effect');
					box.next().addClass('effect');

					return false;
				}

				box.addClass('effect');
			}, 1000);
		});

		$(elements).on('blur', function(){
			var box = $(this).closest(".form-seguro .ufb-form-field-wrap").next();

			setTimeout(function() {
				if(box.css('display') == 'none') {
					do{
						box = box.next('.ufb-form-field-wrap');
					}while(box.css('display') == 'none');

					if(box.length > 0){
						$('html, body').animate({
						  	scrollTop: box.offset().top - 200
						}, 'slow');
					}

					return false;
				}

				if(!box.hasClass('form-header')){
					$('html, body').animate({
						  	scrollTop: box.offset().top - 200
					}, 'slow');

					return false;
				}

				$('html, body').animate({
					  	scrollTop: box.next('.ufb-form-field-wrap').offset().top - 200
				}, 'slow');
			}, 200);

			$(this).unbind('blur');
		});
	},

	checkErrors: function () {
		var elements = $('.ufb-error');

		$('.ufb-next-step, .ufb-form-submit').mousedown(function () {

			setTimeout(function () {

				$.each(elements, function (key, val) {
					if ($(this).text().length > 0) {
					  	$('html, body').animate({
					  		scrollTop:$(this).offset().top - 200
					  	}, 'slow');

					  	return false;
					}
				});
			}, 2000);
		});
	},

	blockHideFields: function(){
		setInterval(function(){
	        var trigger_fields   = $('.ufb-each-step-wrap').not(':hidden').find('.ufb-condition-trigger');
	        var step_button      = $('.ufb-each-step-wrap').not(':hidden').find('.ufb-next-step');
	        var check            = [];

	        //Trocando variaveis para etapa unica
	        trigger_fields       = trigger_fields.length > 0 ? trigger_fields : $('.ufb-form-wrapper').find('.ufb-condition-trigger');
	        step_button          = step_button.length > 0 ? step_button : $('.ufb-form-wrapper').find('.ufb-form-submit');

	        $.each(trigger_fields,function(key, val){
	            if(!$(this).is(':checked'))
	                return true;

	            let conditions_wrap   = $(this).closest('.ufb-form-field').find('.ufb-conditions-wrap');
	            let conditions_fields = conditions_wrap.find('input');

	            $.each(conditions_fields, function(key, val){
	                let field_id   = $(this).data('effect-field');
	                let field_wrap = $('[data-field-id="'+ field_id +'"]');

	                if(field_wrap.is(':visible')){
	                    let field = field_wrap.find('input, select, textarea');

	                    if(!field.val()){
	                        check.push(field.attr('name'));
	                    }else if(field.attr('type') == 'radio'){
	                        if(!field.is(':checked')){
	                            check.push(field.attr('name'));
	                        }
	                    }
	                }
	            });
	        });

	        if(check.length > 0){
	            step_button.addClass('disable');
	            step_button.prop('disabled', true);
	        }else{
	            step_button.removeClass('disable');
	            step_button.prop('disabled', false);
	        }
	    }, 1000);
	}
}