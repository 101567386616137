var FormBuilderAuto = {
    load: function() {
        var seguradoras      = $('.seguradoras select');
        var cep              = $('.cep input');
        var chassiModal      = $('#chassiModal');
        var habModal         = $('#habModal');
        var placa            = $('.placa input');
        var chassi           = $('.chassi input');
        var timeHabilitation = $('.tempo_habilitacao select');
        var exampleChassi    = {
            body: '<div class="image-content">' +
                    '<div class="apolice-image">' +
                        '<img class="chassi-image" src="'+image_path+'chassi.jpg" alt="Exemplo Chassi">' +
                    '</div>' +
                  '</div>'
        };
        var exampleTime      = {
            body: '<div class="image-content">' +
                    '<div class="apolice-image">' +
                        '<img class="hab-image" src="'+image_path+'hab.jpg" alt="Exemplo Chassi">' +
                    '</div>' +
                  '</div>'
        };

        chassi.after(exampleChassi.body);
        $('.chassi-image').unbind('click').on('click', function() {
            chassiModal.modal('show');
        });

        timeHabilitation.after(exampleTime.body);
        $('.hab-image').unbind('click').on('click', function() {
            habModal.modal('show');
        });

        $('.tipo_seguro').on('change', function(){
            var options  = ['<option value="">Selecione</option>'];

            $.each(titulos,function(key, val){
                options.push('<option value="'+val+'">'+val+'</option>');
            });

            //Load no select de seguradoras
            seguradoras.html(options.join(""));
        });

        seguradoras.unbind('change');

        seguradoras.on('change',function(){
            var post_title    = $(this).val();
            var apolice_modal = $('#apoliceModal');
            $('.seguradoras .image-content').remove();
            //Inserindo imagem exemplo da apolice caso haja
            AjaxRequest.getApoliceImage(post_title, function(element){
                apolice_modal.find('.modal-title').html(element.title);
                apolice_modal.find('.modal-body').html(element.body);
                // apolice_modal.modal('show');
                Form.infoButton(element);
            });

        });

        AjaxRequest.getMarcas(function(options) {

            // Load no select de marca
            $('.marca_veiculo select').html(options.join(""));

            // Adiciona o event listener para buscar o modelo
            $('.marca_veiculo select').on('change', function() {
                var codigo_marca = $(this).find('option:selected').data('code');

                if(!codigo_marca){
                    $('.modelo_veiculo select').html('<option value="">Por favor escolha uma marca primeiro</option>');
                    return null;
                }

                $('.modelo_veiculo select').html('<option value="">Carregando..</option>');

                AjaxRequest.getModelos(codigo_marca, function(options) {
                    $('.modelo_veiculo select').html(options.join(""));
                });
            });
        });

        placa.on('keyup', function() {
            let reg = /[a-z]/;
            let val = $(this).val();

            if(val.match(reg)){
                $(this).val(val.toUpperCase());
            }
        });

        chassi.on('keyup', function() {
            let reg = /[a-z]/;
            let val = $(this).val();

            if(val.match(reg)){
                $(this).val(val.toUpperCase());
            }
        });

        // Transforma os selects para chosen
        //$('.ufb-form-field select').removeClass('ufb-form-dropdown ').chosen();

        // Transforma em span as label com valores a serem substituidos
        FormBuilderAuto.changeLabelToSpan('form_veic_marca', 'marca');
        FormBuilderAuto.changeLabelToSpan('form_veic_name', 'veículo');
        FormBuilderAuto.changeLabelToSpan('form_user_name', 'cliente');
        FormBuilderAuto.changeLabelToSpan('form_segurado_name', 'segurador');
        FormBuilderAuto.changeLabelToSpan('form_condut_name', 'condutor');
        FormBuilderAuto.changeLabelToSpan('form_prop_name', 'proprietário');
        FormBuilderAuto.changeLabelToSpan('form_cep_name', '');

        // Atualiza os spans de acordo com a alteração do valor
        $('.marca_veiculo select').on('change', function() {
            FormBuilderAuto.updateLabel('form_veic_marca', $(this).val(), 'marca');
        });

        $('.modelo_veiculo select').on('change', function() {
            FormBuilderAuto.updateLabel('form_veic_name', $(this).val(), 'veículo');
        });

        $('.user_name input').on('focusout', function() {
            var name = $(this).val().split(" ");

            FormBuilderAuto.updateLabel('form_user_name', name[0], 'cliente');
        });

        $('.segurado_name input').on('focusout', function() {
            var name = $(this).val().split(" ");
            var user = $('.user_name input').val().split(" ");

            FormBuilderAuto.updateLabel('form_segurado_name', name[0], user[0]);
        });

        $('.prop_nome input').on('focusout', function() {
            var name = $(this).val().split(" ");

            FormBuilderAuto.updateLabel('form_prop_name', name[0], user[0]);
        });

        $('.conductor_name input').on('focusout', function() {
            var name = $(this).val().split(" ");

            FormBuilderAuto.updateLabel('form_condut_name', name[0], user[0]);
        });

        $('.seguro-automovel .cep input').on('focusout', function() {
            var cep      = $(this).val();
            var that     = this;

            AjaxRequest.getEndereco(cep, false, function(address) {
                FormBuilderAuto.updateLabel('form_cep_name', address, "Endereço não encontrado");

                if(!address){
                    $(that).val('');
                }
            });
        });

        $('.choose_segurado input[value="sim"], .choose_segurado input[value="sim"] + label').on('click', function() {
            var user = $('.form_user_name').first().text().split(" ");

            FormBuilderAuto.updateLabel('form_segurado_name', user[0], 'cliente');
        });

        $('.choose_prop input[value="sim"], .choose_prop input[value="sim"] + label').on('click', function() {
            var segurador = $('.form_segurado_name').first().text().split(" ");

            FormBuilderAuto.updateLabel('form_prop_name', segurador[0], 'segurador');
        });

        $('.choose_condutor input[value="sim"], .choose_condutor input[value="sim"] + label').on('click', function() {
            var prop = $('.form_prop_name').first().text().split(" ");

            FormBuilderAuto.updateLabel('form_condut_name', prop[0], 'condutor');
        });
    },
    changeLabelToSpan: function(handler, replace) {
        var re    = new RegExp('{{' + handler + '}}');
        var label = $('.ufb-control-label, .ufb-form-field');

        $.each(label, function(index, el) {
            var text = $(el).html();

            if(text.indexOf('{{'+handler+'}}') !== -1){
                $(el).html($.parseHTML(text.replace(re, '<span class="' + handler + '">' + replace + '</span>')));
            }
        });
    },
    updateLabel: function(selector, replace, default_value) {
        var span = $('.ufb-control-label .' + selector +', .ufb-form-field .' + selector);

        $.each(span, function(index, el) {
            $(el).text((replace !== "") ? replace : default_value);
        });
    }
};