var FormBuilderViagem = {
    load: function() {
        var continentes = $('.continentes select');
        var paises      = $('.paises select');
        var estados     = $('.estados_destino select');
        var cidades     = $('.cidades_destino select');

        AjaxRequest.getContinentes(function(options){
            //Listando continentes no select
            continentes.html(options.join(""));
            continentes.trigger("chosen:updated");

            //Event listener para continentes
            continentes.on('change', function() {
                var geoId = continentes.find('option:selected').data('code');

                paises.html('<option value="">Carrengando..</option>');
                paises.trigger("chosen:updated");

                AjaxRequest.getPaises(geoId, function(options){
                    paises.html(options.join(""));
                    paises.trigger("chosen:updated");
                });
            });

            //Event listener para paises
            paises.on('change', function() {
                var geoId = paises.find('option:selected').data('code');

                estados.html('<option value="">Carrengando..</option>');
                estados.trigger("chosen:updated");

                AjaxRequest.getEstados(geoId, function(options){
                    estados.html(options.join(""));
                    estados.trigger("chosen:updated");
                });
            });

            //Event listener para estados
            estados.on('change', function() {
                var geoId = estados.find('option:selected').data('code');

                cidades.html('<option value="">Carrengando..</option>');
                cidades.trigger("chosen:updated");

                AjaxRequest.getCidades(geoId, function(options){
                    cidades.html(options.join(""));
                    cidades.trigger("chosen:updated");
                });
            });
        });
    }
};