var customSearchEl = document.querySelectorAll('#custom-search');

if (customSearchEl.length > 0) {
    google.load('search', '1', {
        language: 'pt',
        style: google.loader.themes.V2_DEFAULT
    });

    google.setOnLoadCallback(function() {

        var key = '003854072278873777024:ig0_zjwe94g';
        var customSearchOptions = {
            customSearchOptions: true,
            enableOrderBy: true,
            orderByOptions: {
                keys: [
                    { label: 'Relevância', key: '' },
                    { label: 'Date', key: 'date' }
                ]
            }
        };

        var queryParamName = 's';
        var urlParams = parseParamsFromUrl();

        var options = new google.search.DrawOptions();
        var customSearchControl = new google.search.CustomSearchControl(key, customSearchOptions);

        options.enableSearchResultsOnly();
        options.setAutoComplete(true);

        customSearchControl.setResultSetSize(google.search.Search.FILTERED_CSE_RESULTSET);
        customSearchControl.draw('custom-search', options);
        customSearchControl.setLinkTarget(google.search.Search.LINK_TARGET_SELF);

        if (urlParams[queryParamName]) {
            customSearchControl.execute(urlParams[queryParamName]);

            var interval = setInterval(function(){
                var adBlock = document.querySelectorAll('.gsc-adBlock');
                if (adBlock.length > 0){
                    adBlock[0].style.display = 'none';
                    clearInterval(interval);
                }
            }, 100);
        }

        // Buscando pelo parâmetro "busca" na url
        function parseParamsFromUrl() {
            var params = {};
            var parts = window.location.search.substr(1).split('&');

            for (var i = 0; i < parts.length; i++) {
                var keyValuePair = parts[i].split('=');
                var key = decodeURIComponent(keyValuePair[0]);

                params[key] = keyValuePair[1]
                    ? decodeURIComponent(keyValuePair[1].replace(/\+/g, ' '))
                    : keyValuePair[1];
            }

            return params;
        }
    }, true);
}