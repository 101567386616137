$(function() {

	var cpf  = $('.cpf input');
	var cnpj = $('.cnpj input');

	cpf.on('focusout', function() {
		Validate.cpf($(this));
	});

	cnpj.on('focusout', function() {
		Validate.cnpj($(this));
	});

	var Validate = {
		cpf: function(element) {
			var i;
	        var cpf    = element.val();
	        var p1_cpf = cpf.substr(3,1);
	        var p2_cpf = cpf.substr(7,1);
	        var p3_cpf = cpf.substr(11,1);

	        if(!cpf) return false;

	        if(p1_cpf=="." && p2_cpf=="." && p3_cpf=="-"){
	            var exp = /\.|\-/g
	            var s   = cpf.toString().replace( exp, "" );

	            if(s=="11111111111" ||
	               s=="22222222222" ||
	               s=="33333333333" ||
	               s=="44444444444" ||
	               s=="55555555555" ||
	               s=="66666666666" ||
	               s=="77777777777" ||
	               s=="88888888888" ||
	               s=="99999999999" ||
	               s=="00000000000"){
	                 alert("CPF Inválido");
	                 element.val('');

	                 return false;
	            }else{
	                var c  = s.substr(0,9);
	                var dv = s.substr(9,2);
	                var d1 = 0;

	                for (var i = 0; i < 9; i++){
	                        d1 += c.charAt(i)*(10-i);
	                }

	                if (d1 == 0){}

	                d1 = 11 - (d1 % 11);

	                if (d1 > 9) d1 = 0;

	                if (dv.charAt(0) != d1){
                        alert("CPF Inválido");
                        element.val('');

                        return false;
	                }

	                d1 *= 2;

	                for (var i = 0; i < 9; i++){
	                        d1 += c.charAt(i)*(11-i);
	                }

	                d1 = 11 - (d1 % 11);

	                if (d1 > 9) d1 = 0;

	                if (dv.charAt(1) != d1){
                        alert("CPF Inválido");
                        element.val('');

                        return false;
	                }

	                return true;
	            }
	        }else{
	           alert("CPF Inválido");
	           element.val('');

	           return false;
	        }
		},
		cnpj: function(element) {
			var cnpj = element.val();

		    cnpj = cnpj.replace(/[^\d]+/g,'');

		    if(cnpj == '') return false;

		    if (cnpj.length != 14){
		        alert("CNPJ Inválido");
		        return false;
		    }

		    // Elimina CNPJs invalidos conhecidos
		    if (cnpj == "00000000000000" ||
		        cnpj == "11111111111111" ||
		        cnpj == "22222222222222" ||
		        cnpj == "33333333333333" ||
		        cnpj == "44444444444444" ||
		        cnpj == "55555555555555" ||
		        cnpj == "66666666666666" ||
		        cnpj == "77777777777777" ||
		        cnpj == "88888888888888" ||
		        cnpj == "99999999999999") {
		        alert("CNPJ Inválido");
		    	element.val('');

		        return false;
		    }

		    // Valida DVs
		    var tamanho = cnpj.length - 2
		    var numeros = cnpj.substring(0,tamanho);
		    var digitos = cnpj.substring(tamanho);
		    var soma    = 0;
		    var pos     = tamanho - 7;

		    for (var i = tamanho; i >= 1; i--) {
		      soma += numeros.charAt(tamanho - i) * pos--;
		      if (pos < 2)
		            pos = 9;
		    }
		    var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

		    if (resultado != digitos.charAt(0)) {
		        alert("CNPJ Inválido");
		        element.val('');

		        return false;
		    }

		    tamanho = tamanho + 1;
		    numeros = cnpj.substring(0,tamanho);
		    soma    = 0;
		    pos     = tamanho - 7;

		    for (var i = tamanho; i >= 1; i--) {
		      soma += numeros.charAt(tamanho - i) * pos--;
		      if (pos < 2)
		            pos = 9;
		    }
		    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

		    if (resultado != digitos.charAt(1)) {
		        alert("CNPJ Inválido");
		        element.val('');

		        return false;
		    }

		    return true;
		}
	};
});