var FormBuilderResidencia = {
    load: function() {
        var seguradoras = $('.seguradoras_residencial select');
        var endereco    = $('.endereco textarea');
        var estados     = $('.estados select');
        var cidades     = $('.cidades select');

        $('.tipo_seguro_residencial input[type=radio]').on('change',function(){
            var options  = ['<option value="">Selecione</option>'];

            $.each(titulos,function(key, val){
                options.push('<option value="'+val+'">'+val+'</option>');
            });

            //Load no select de seguradoras
            seguradoras.html(options.join(""));
            seguradoras.trigger("chosen:updated");
        });

        AjaxRequest.getEstados("3469034",function(options){

            //Listando opções de estado
            estados.html(options.join(""));
            estados.trigger("chosen:updated");

            //Adicionando eventListener para select de estados
            estados.on('change', function(){
                var geoId = $(this).find('option:selected').data('code');

                cidades.html('<option value="">Carregando..</option>');
                cidades.trigger("chosen:updated");

                AjaxRequest.getCidades(geoId, function(options){
                    cidades.html(options.join(""));
                    cidades.trigger("chosen:updated");
                });
            });
        });

        $('.seguro-residencial .cep input').on('focusout', function() {
            var cep      = $(this).val();
            var that     = this;

            AjaxRequest.getEndereco(cep,true, function(address) {


                if(!address){
                    $(that).val('');
                }else{
                    endereco.val(address.bairro + ', ' +
                                address.logradouro + ' - ' +
                                address.localidade + '/' +
                                address.uf);
                    endereco.focus();
                }
            });
        });
    }
};