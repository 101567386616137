var Header = {
	dropdown: function () {
		if ($(window).width() <= 768) {
			$('.header .dropdown').click(function () {
				$(this).find('ul').stop().slideToggle(500);
			});
		}
	},

	fixed: function () {
		if ($('.section-banner-home').length < 1) {
			$(".header-content").removeClass('dark');
		} else {
			$(window).scroll(function () {
				var banner = $('.section-banner-home').innerHeight();

				if ($(window).scrollTop() >= banner){
					$(".header-content").removeClass('dark');
				} else {
					$(".header-content").addClass('dark');
				}
			});
		}
		$(window).scroll(function () {
			var top = $('.header-top').innerHeight();

			if ($(window).scrollTop() >= top){
				$(".header-content").addClass('fixed');
			} else {
				$(".header-content").removeClass('fixed');
			}
		});
	}
};

var Carousel = {
	carousel: function (wrapper, prev, next, numberXs, numberSm, numberMd, paddingXs, paddingSm, paddingMd, play, margin, loop, nav, dots) {
		$(wrapper).owlCarousel({
			loop: loop,
			margin: margin,
			autoplay: play,
			autoplayHoverPause: true,
			autoplayTimeout: 3000,
			dots: dots,
			nav: nav,
			navRewind: true,
			onInitialized: function() {
				var order       = [2,3,5,1,0,4];
				var realItems   = this._items;
				var newItems    = [];
				var father      = $(realItems[0]).closest('.plans-carousel');
				var slide_stage = father.find('.owl-stage');

				if(father.length > 0){

					//Ordenando items
					for (var i = 0;i < realItems.length;i++){

						$(realItems[i][0]).removeClass('active');
						newItems.push(realItems[order[i]]);

						var style   = $(newItems[i]).attr('style');
						var active  = i < 3 ? 'active' : '';

						$(realItems[i]).replaceWith('<div class="owl-item '+active+'" style="'+style+'">'+$(newItems[i]).html()+'</div>');
					}

					father.find('.cloned').remove();

					//Ordenando itens clonados
					for (var i = 0;i < newItems.length;i++){
						var style   = $(newItems[i]).attr('style');

						if(i < 3)
							slide_stage.append('<div class="owl-item cloned" style="'+style+'">'+$(newItems[i]).html()+'</div>');
						else
							slide_stage.prepend('<div class="owl-item cloned" style="'+style+'">'+$(newItems[i]).html()+'</div>');
					}
				}
			},
			navText: [
				"<button class='controls "+ prev +"'><i class='fa fa-angle-left fa-fw'></i></button>",
				"<button class='controls "+ next +"'><i class='fa fa-angle-right fa-fw'></i></button>"
			],
		    responsive:{
				0:{
					items:numberXs,
					stagePadding: paddingXs
				},
				768:{
					items:numberSm,
					stagePadding: paddingSm
				},
				992:{
					items:numberMd,
					stagePadding: paddingMd
				}
			}
		});
	}
};

var Galery = {
	toggle: function (galery) {
		galery + $('.thumb-list li img').click(function () {
			var zoom = $(this).attr('data-src');

			galery + $('.galery-zoom a:first-child img').attr('src', zoom);// jshint ignore:line
			galery + $('.galery-zoom a:first-child').attr('href', zoom);// jshint ignore:line
		});// jshint ignore:line
	}
};

var Acordeon = {
	quests: function () {
		$('.quests-content li').click(function () {
			$(this).find('main').slideToggle();
			$(this).find('.fa').toggleClass('fa-chevron-down fa-chevron-up');
		});
	}
}

var iFeed = {
	init: function() {
		var opts = {
		    resolution: 'thumbnail',
		    get: 'user',
		    userId: parseInt(user.id),
		    accessToken: user.token,
		    sortBy: 'least-liked',
		    template: '<div><a target="_blank" href="{{link}}"><img src="{{image}}" /></a></div>',
		    limit: 6
		};
		var feed_footer  = document.getElementById('ifeed-footer');
		var feed_sidebar = document.getElementById('ifeed-sidebar');

    if (feed_footer != null)
        feed_footer.ifeed(opts);
    if (feed_sidebar != null)
        feed_sidebar.ifeed(opts);
	}
}

var App = {
	init: function () {
		$('a[href="#"]').click(function (e) {
				e.preventDefault();
		});

		Carousel.carousel('.plans-carousel-content', 'none', 'none', 1, 2, 3, 50, 100, 100, true, 30, true, false, false);
		Carousel.carousel('.partner-carousel', 'none', 'none', 1, 2, 4, 10, 10, 10, true, 30, false, false, true);
		Carousel.carousel('.thumb-list', 'galery-prev', 'galery-next', 4, 4, 4, 0, 0, 0, false, 5, false, true, false);
		Header.fixed();
		iFeed.init();
	}
};

$(document).ready(function(){
	App.init();
});

$(window).on('load', function() {
	Galery.toggle();
	Acordeon.quests();
	Header.dropdown();
});

$(window).on('resize', function() {
	Header.dropdown();
});

window.addEventListener('popstate', function (e) {
});